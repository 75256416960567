<template>
	<form @submit.prevent="save" class="form-bare">
		<label class="flex flex-col font-bold w-full">
			<span>Name</span>
			<input
				class="border form-input mt-1 p-2"
				name="name"
				v-model="resource.name"
				placeholder="Project Name"
				required />
		</label>
		<v-button type="submit" class="btn btn-success" :loading="crudLoading">{{ crudName }} Project</v-button>
	</form>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import Button from "@/components/ui/Button";

export default {
	mixins: [crudable],

	mounted() {
		this.setResourceEndpoint("teamProjects", {
			team_id: this.teamId,
			project_type: this.projectType,
		});
	},

	components: {
		"v-button": Button,
	},

	data() {
		return {};
	},

	props: ["teamId", "projectType"],

	methods: {},

	computed: {
		crudName() {
			if (this.resourceId) {
				return "Save";
			}
			return "Create";
		},
	},
};
</script>
<style></style>
