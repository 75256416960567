<template>
	<div>
		<!-- <Tabs>
            <Tab name="Projects" :selected="true">
                <div class="w-12/12">
                    <div class="flex justify-between">
                        <div class="w-5/12">
                            <div class="flex justify-between mb-3">
                                <div class="m-3 font-bold text-lg">Prospects</div>
                                <v-button @click="createProject" class="btn btn-small btn-add-new h-10">New<span class="ml-3" v-html="plus_icon"></span></v-button>
                            </div>
                            <ProjectList :projects="projects" :teamId="teamId" projectType="prospects"/>
                        </div>

                        <div class="w-5/12">
                            <div class="m-3 font-bold text-lg">Markets</div>
                            <div class="m-3 tracking-widest">Coming soon...</div>
                        </div>
                    </div>
                </div>
            </Tab>
        </Tabs> -->

		<div class="w-12/12">
			<div class="flex flex-wrap flex-col md:flex-row">
				<div class="w-11/12 md:w-5/12 m-4">
					<div class="flex justify-between mb-3">
						<div class="m-3 font-bold text-lg">Company analysis</div>
						<v-button
							v-if="activeTeamPermission != 'READ'"
							@click="createCompanyAnalysisProject"
							class="btn btn-small btn-add-new h-8"
							>New<span class="ml-3" v-html="plus_icon"></span
						></v-button>
					</div>
					<CompanyAnalysisProjectTable :projects="companyAnalysisProjects" :teamId="teamId" />
				</div>

				<div class="w-11/12 md:w-5/12 m-4">
					<div class="flex justify-between mb-3">
						<div class="m-3 font-bold text-lg">Lead generation</div>
						<v-button
							v-if="activeTeamPermission != 'READ'"
							@click="createProspectsProject"
							class="btn btn-small btn-add-new h-8"
							>New<span class="ml-3" v-html="plus_icon"></span
						></v-button>
					</div>
					<ProspectsProjectTable :projects="prospectsProjects" :teamId="teamId" />
				</div>

				<!-- <div class="w-11/12 md:w-5/12 border-2 m-4">
                    <div class="m-3 font-bold text-lg">Market analysis</div>
                    <div class="m-3 tracking-widest">Coming soon...</div>
                </div> -->
			</div>
		</div>

		<Modal v-show="newProspectsProjectModal" :widthPercent="60" @close="newProspectsProjectModal = false">
			<template v-slot:header>
				<h3>New Prospects project</h3>
			</template>

			<template v-slot:body>
				<CreateProjectForm
					:teamId="teamId"
					projectType="prospects"
					@crudActionStatus="handleProspectsProjectCrud" />
			</template>
		</Modal>

		<Modal
			v-show="newCompanyAnalysisProjectModal"
			:widthPercent="60"
			@close="newCompanyAnalysisProjectModal = false">
			<template v-slot:header>
				<h3>New Company Analysis project</h3>
			</template>

			<template v-slot:body>
				<CreateProjectForm
					:teamId="teamId"
					projectType="company-analysis"
					@crudActionStatus="handleCompanyAnalysisProjectCrud" />
			</template>
		</Modal>
	</div>
</template>

<script>
import { crudable } from "@/mixins/Crudable";
import ProspectsProjectTable from "@/components/teams/ProspectsProjectTable";
import CompanyAnalysisProjectTable from "@/components/teams/CompanyAnalysisProjectTable";
import CreateProjectForm from "@/components/teams/CreateProjectForm";
import Modal from "@/components/Modal";
import Button from "@/components/ui/Button";
import { mapGetters } from "vuex";
// import Tab from '@/components/ui/Tab';
// import Tabs from '@/components/ui/Tabs';

const back_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                        </svg>`;
const plus_icon = `<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>`;

export default {
	mixins: [crudable],

	created() {
		if (this.teamId) {
			this.resourceId = this.teamId;
			this.setResourceEndpoint("teams");
			this.fetchOne();
		}
	},

	mounted() {
		// Updates the projects table if a deletion has occurred
		this.getProspectProjectList();
		this.getCompanyAnalysisProjectList();
	},

	components: {
		ProspectsProjectTable,
		CompanyAnalysisProjectTable,
		CreateProjectForm,
		Modal,
		// Tab,
		// Tabs,
		"v-button": Button,
	},

	props: ["teamId"],

	data() {
		return {
			newProspectsProjectModal: false,
			newCompanyAnalysisProjectModal: false,
			prospectsProjects: [],
			companyAnalysisProjects: [],
			back_icon,
			plus_icon,
		};
	},

	methods: {
		createProspectsProject() {
			this.newProspectsProjectModal = true;
		},

		handleProspectsProjectCrud(resourceId, e, payload) {
			if (e == "created") {
				this.getProspectProjectList();
				this.newProspectsProjectModal = false;
			}
		},

		createCompanyAnalysisProject() {
			this.newCompanyAnalysisProjectModal = true;
		},

		handleCompanyAnalysisProjectCrud(resourceId, e, payload) {
			if (e == "created") {
				this.getCompanyAnalysisProjectList();
				this.newCompanyAnalysisProjectModal = false;
			}
		},

		async getProspectProjectList() {
			try {
				let response = await this.$api
					.resource("teamProjects", {
						team_id: this.teamId,
						project_type: "prospects",
					})
					.fetchAll();
				this.prospectsProjects = response.data.projects;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},

		async getCompanyAnalysisProjectList() {
			try {
				let response = await this.$api
					.resource("teamProjects", {
						team_id: this.teamId,
						project_type: "company-analysis",
					})
					.fetchAll();
				this.companyAnalysisProjects = response.data.projects;
			} catch (e) {
				console.log(`Blablah: ${e}`);
			}
		},
	},

	watch: {
		resourceStatus(status) {
			// if (status.status == 'loaded') {
			//     this.projects = this.resource.projects;
			// } else
			if (status.status == "deleted") {
				this.$router.push({
					name: "teams",
				});
			} else if (status.status == "delete_error") {
				this.deletingTeam = false;
				this.deleteError = this.errorResource.title;
			}
		},
		$route(to, from) {
			// console.log(to, from)
			this.newProspectsProjectModal = false;
			this.newCompanyAnalysisProjectModal = false;
		},
	},

	computed: {
		...mapGetters(["activeTeamPermission"]),
	},
};
</script>

<style></style>
