<template>
	<div class="flex flex-col">
		<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
			<div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
				<div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
					<table class="min-w-full divide-y divide-gray-200">
						<thead class="bg-gray-50">
							<tr>
								<th
									scope="col"
									class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
									Project name
								</th>
								<th scope="col" class="relative px-6 py-3">
									<span class="sr-only">View</span>
								</th>
							</tr>
						</thead>
						<tbody class="bg-white divide-y divide-gray-200">
							<tr v-for="project in projects" :key="project.id">
								<td class="px-6 py-4 whitespace-nowrap">
									<div class="text-sm text-gray-900">{{ project.name }}</div>
								</td>
								<td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
									<button
										@click="viewProject(project.project_id)"
										class="btn btn-default-outline btn-small">
										View
									</button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import {crudable} from '@/mixins/Crudable';
import { mapGetters } from "vuex";
import Button from "@/components/ui/Button";

export default {
	// mixins: [crudable],

	created() {},

	components: {},

	data() {
		return {};
	},

	props: ["projects", "teamId"],

	methods: {
		viewProject(projectId) {
			this.$router.push({
				name: "prospects-project",
				params: { teamId: this.teamId, projectId: projectId },
			});
		},
	},

	computed: {
		// ...mapGetters([
		//     'auth',
		//     'appLoading'
		// ])
	},
};
</script>
<style></style>
